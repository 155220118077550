:root {
    --primary: #003c66;
    --secondary: #08B285;
    --blue: #00b1c3;
    --red: #92004D;
    --gray-light: #f2f0f1;
    --gray-dark: #cfcfcf;
    --light: #F1F3FA;
    --dark: #1C2035;
}

.color-primary{
    color:var(--primary)
}
.color-secondary{
    color:var(--secondary)
}
.color-light{
    color:var(--light)
}
.color-dark{
    color:var(--dark)
}
.color-blue{
    color:var(--blue)
}
.color-red{
    color:var(--red)
}
.color-gray{
    color:var(--gray-dark)
}

.text-left{
    text-align: left;
}

.link{
  text-decoration: none;
  font-weight: bold;
  color: #1998e5;
}

@font-face {
    font-family: Poppins-Regular;
    src: url('./../fonts/Poppins-Regular.ttf');
}

@font-face {
    font-family: Poppins-Light;
    src: url('./../fonts/Poppins-Light.ttf');
}

body{
    font-family: Poppins-Regular;
}
.arrowDown{
    width: 100%;
    color: white;
    font-size: 30px;
    position: absolute;
    left: 0;
    bottom: 0;
}
.breadcrumb a{
  color:var(--primary)
}
h1{
  font-family: Poppins-Light;
  letter-spacing: 7px;
}

.linea-header{
  display: inline-block;
  width: 50px;
  height: 3px;
  background-color: var(--light);
}

.navbar-expand-lg .navbar-nav {
    flex-direction: row;
}

.linea-menu{
    border-right: 1px solid white;
    height: 15px;
    max-width: 150px;
    background: linear-gradient(90deg, #FFFFFF 50%, #FFFFFF 50%);
    padding: 0;
    margin: 0 auto 0 auto;
    position: relative;
}

.btn-border-white{
    border: 1px solid #fff;
    border-radius: 0px;
    color: #fff;
    -webkit-transition: all .15s ease-in-out;
    transition: all .5s ease-in-out;
}

.btn-border-white:hover{
    color: #1C2035;
    background-color: #fff;
    border: 1px solid #fff;
}


.btn-primary{
  display: flex;
  align-items: center;
}

.btn-border-primary{
  border: 1px solid var(--gray-dark);
  border-radius: 50px;
  color: var(--primary);
  -webkit-transition: all .15s ease-in-out;
  transition: all .5s ease-in-out;
  width: 40px;
  height: 40px;
  display: inline-block;
}

.btn-primary:hover .btn-border-empresas{
  color: var(--primary);
  border: 1px solid var(--primary);
}

.btn-primary-icon{
  color:var(--primary);
  position: absolute;
  font-size: 25px;
  margin-left: -10px;
  -webkit-transition: all .15s ease-in-out;
  transition: all .5s ease-in-out;
}

.btn-primary:hover .btn-primary-icon{
  margin-left: 5px;
}


.btn-fincas{
    display: flex;
    align-items: center;
}

.btn-border-fincas{
    border: 1px solid var(--dark);
    border-radius: 50px;
    color: var(--red);
    -webkit-transition: all .15s ease-in-out;
    transition: all .5s ease-in-out;
    width: 40px;
    height: 40px;
    display: inline-block;
}

.btn-fincas:hover .btn-border-fincas{
    color: var(--red);
    border: 1px solid var(--red);
}

.btn-fincas-icon{
    color:var(--red);
    position: absolute;
    font-size: 25px;
    margin-left: -10px;
    -webkit-transition: all .15s ease-in-out;
    transition: all .5s ease-in-out;
}

.btn-fincas:hover .btn-fincas-icon{
    margin-left: 5px;
}

.btn-empresas{
  display: flex;
  align-items: center;
  color: var(--light);
}
.btn-empresas:hover{
  color: var(--blue);
  transition: all .5s ease-in-out;
}

.btn-border-empresas{
    border: 1px solid var(--light);
    border-radius: 50px;
    color: var(--blue);
    -webkit-transition: all .15s ease-in-out;
    transition: all .5s ease-in-out;
    width: 40px;
    height: 40px;
    display: inline-block;
}

.btn-empresas:hover .btn-border-empresas{
    color: var(--blue);
    border: 1px solid var(--blue);
}

.btn-empresas-icon{
    color:var(--blue);
    position: absolute;
    font-size: 25px;
    margin-left: -10px;
    -webkit-transition: all .15s ease-in-out;
    transition: all .5s ease-in-out;
}

.btn-empresas:hover .btn-empresas-icon{
    margin-left: 5px;
}

.btn-formacion{
    display: flex;
    align-items: center;
}

.btn-border-formacion{
    border: 1px solid var(--primary);
    border-radius: 50px;
    color: var(--secondary);
    -webkit-transition: all .15s ease-in-out;
    transition: all .5s ease-in-out;
    width: 40px;
    height: 40px;
    display: inline-block;
}

.btn-formacion:hover .btn-border-formacion{
    color: var(--secondary);
    border: 1px solid var(--secondary);
}

.btn-formacion-icon{
    color:var(--secondary);
    position: absolute;
    font-size: 25px;
    margin-left: -10px;
    -webkit-transition: all .15s ease-in-out;
    transition: all .5s ease-in-out;
}

.btn-formacion:hover .btn-formacion-icon{
    margin-left: 5px;
}
.letter-spacing{
    letter-spacing: 2px;
}
.navbar{
    position: absolute;
    padding: 10px 0;
    background-color: transparent;
    width: 100%;
    z-index: 3;
    border-bottom: 1px solid #fff;
}
.navb-link {
    display: inline-block;
    background-color: rgba(255,255,255,0.5);
    text-decoration: none;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    padding: 2px;
  }
  
  .nav-icon {
    background-color: transparent;
  }

.img-gl{
    background: url(../images/slide-home-grupo-lae.jpg) no-repeat center / cover;
    height: 100vh;
    width: 100%;
    display: table;
    position: relative;
}
.content-gl{
    display: table-cell;
    vertical-align: middle;
    padding-top: 75px;
}
.text-gl{
    position: relative;
    max-width: 890px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    text-align: center;
    color: var(--light);
    z-index: 2;
}
.text-gl img{
    width: 370px;
    margin-bottom: 20px;
}
@media (max-width: 420px) {
  .text-gl img{
    width: 200px;
    margin-bottom: 20px;
  }
}

.overlay:before {
    content: '';
    background-color: rgba(255,255,255,0.1);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

.img-fi{
    background: url(../images/slide-home-grupo-lae-fincas.jpg) no-repeat center / cover;
    height: 70vh;
    width: 100%;
    display: table;
    position: relative;
}
.content-fi{
    display: table-cell;
    vertical-align: middle;
    padding-top: 75px;
}
.contenido-fi{
    display: flex;
    align-items: center;
    height: 100%;
}
.text-fi{
    position: relative;
    max-width: 890px;
    width: 100%;
    margin: 0 auto 0 10%;
    padding: 0 15px;
    text-align: left;
    color: var(--dark);
    z-index: 2;
}

.img-em{
    background: url(../images/slide-home-grupo-lae-empresas.jpg) no-repeat center / cover;
    height: 70vh;
    width: 100%;
    display: table;
    position: relative;
}
.content-em{
    display: table-cell;
    vertical-align: middle;
    padding-top: 75px;
}
.contenido-em{
    display: flex;
    align-items: center;
    height: 100%;
}
.text-em{
    position: relative;
    max-width: 890px;
    width: 100%;
    margin: 0 auto 0 10%;
    padding: 0 15px;
    text-align: left;
    color: var(--light);
    z-index: 2;
}

.img-fo{
    background: url(../images/slide-home-grupo-lae-particulares.jpg) no-repeat center / cover;
    height: 70vh;
    width: 100%;
    display: table;
    position: relative;
}
.content-fo{
    display: table-cell;
    vertical-align: middle;
    padding-top: 75px;
}
.contenido-fo{
    display: flex;
    align-items: center;
    height: 100%;
}
.text-fo{
    position: relative;
    max-width: 890px;
    width: 100%;
    margin: 0 auto 0 10%;
    padding: 0 15px;
    text-align: left;
    color: var(--primary);
    z-index: 2;
}
.text-fo img{
    width: 220px;
    margin-bottom: 20px;
}


/* @media (max-width: 768px) {
    .text-fi{
        margin: 0 auto;
    }
} */


blockquote {
    position: relative;
    padding-left: 1.5rem;
    font-family: "Poppins-Regular";
    font-size: 2rem;
    line-height: 1.25;
    letter-spacing: -0.05rem;
  }
  blockquote:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 6px;
    height: 100%;
    background-color: #A9DFBF;
    border-radius: 60px;
  }
  
  figure {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position-x: center;
  }
  
  .apart {
    position: relative;
    width: 100%;
    height: 70vh;
  }
  figure.img1 {
    background-image: url("./../images/slide-home-grupo-lae-fincas.jpg");
  }
  figure.img2 {
    background-image: url("./../images/slide-home-grupo-lae-empresas.jpg");
  }
  figure.img3 {
    background-image: url("./../images/slide-home-grupo-lae-particulares.jpg");
  }
  
  .apart-inner {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    /* clip: rect(0, auto, auto, 0); */
    clip-path: inset(0 0 0 0);
  }
  @supports (-webkit-overflow-scrolling: touch) {
    .apart-inner {
      clip: unset;
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
              clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
  }
  
  .apart__title {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    /* height: 100%;
    padding: 0 1rem;
    color: white;
    font-family: "Courgette", serif;
    font-size: 8vw;
    letter-spacing: -0.125rem;
    text-align: center; */
  }
  /* @media (min-width: 1200px) {
    .apart__title {
      font-size: 6rem;
    }
  } */
  .content {
    position: relative;
    /* margin: 0 auto 8rem; */
    padding: 2rem;
  }
 /*  .content:before {
    content: "";
    display: block;
    position: absolute;
    top: -100px;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: white;
    z-index: 99;
    -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
            clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  }
   */
  .content__inner {
    margin: 0 auto;
    max-width: 1200px;
  }
  .content__inner > * + * {
    margin-top: 1.5rem;
  }
  .content__inner > blockquote {
    margin: 3rem 0;
  }
/*   
  .content__title {
    font-family: "Courgette", serif;
    font-size: 3rem;
    line-height: 1.25;
    letter-spacing: -0.125rem;
    text-align: center;
  } */
/*   @media (min-width: 600px) {
    .content__title {
      font-size: 4rem;
    }
  } */
/*   
  .content__last {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4rem;
    width: 100%;
    font-family: "Courgette", serif;
    font-size: 1.5rem;
    letter-spacing: -0.125rem;
    text-align: center;
  }
  .content__last:before, .content__last:after {
    content: "";
    flex: 1;
    height: 2px;
    background-color: #A9DFBF;
  }
  .content__last:before {
    margin-right: 1rem;
  }
  .content__last:after {
    margin-left: 1rem;
  } */


.footer {
    background-color: var(--primary);
    color: var(--light);
    padding-top: 95px;
}

.footer a{
    color:var(--light);
    text-decoration: none;
}

.footer .title{
  text-align: left;
}

.footer li, .copyright p{
  font-size: 12px;
}

.f-cont-l{
    width: 250px;
    /* margin: 0 auto; */
    text-align: left;
}

@media (max-width: 620px) {
  .f-cont-l{
    margin: 0 auto;
    text-align: center;
  }
  .footer .title{
    text-align: center;
  }
}


/*** Trends ***/
.trends-img{
  background-size: cover;
  height: 230px;
  background-position: center;
}
.trends-fecha{
  position: absolute;
  bottom: 0;
  right: 0;
  color: white;
  background: black;
  padding: 10px 20px;
  font-size: 12px;
}
.trends-text h2{
  font-size: 1.3rem;
}
.trends-text a{
  text-decoration: none;
}
.trends-text a:hover{
  text-decoration: underline;
}
@media (min-width: 576px){
    .card-columns {
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1;
      display: inline-block;
    }
    
}
@media (min-width: 768px){
    .card-columns {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    display: inline-block;
    }
}
@media (min-width: 992px) {
    .card-columns{
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
        display: inline-block;
    }
}

.pagination {
    justify-content: center;
}
.pagination > li > a {
    display: inline-block;
    min-width: 30px;
    padding: .25rem .5rem;
    outline: none;
    cursor: pointer;
    margin: 0 10px;
    border-radius: 5px;
    border: solid 1px var(--primary);
    color: var(--primary);
    text-decoration: none;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    background-color: var(--primary);
    border-color: var(--primary);
    color: var(--light);
    outline: none;
}

.pagination > li.disabled{
    display: none;
}
.pagination > li.disabled > a{
    cursor: default;
}


/****   TREND   ****/
.owl-theme .owl-nav [class*="owl-"] span {
  font-size: 25px;
  border: 1px solid var(--secondary);
  padding: 0px 15px !important;
  background: transparent;
  color: var(--primary);
  text-decoration: none;
  -webkit-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;
}

.owl-theme .owl-nav [class*="owl-"] span:hover {
  background: var(--primary);
  color: #FFF;
  text-decoration: none;
  border: 1px solid var(--primary);
}


/**** politicas ****/

.politicas h5{
  padding: 30px 0 10px;
}

.politicas li{
  margin: 10px 0;
}

.politicas a{
  color: var(--gray-dark);
  text-decoration: none;
}

.politicas p, .politicas li, .politicas table{
  font-size: 13px;;
}